//
// RocketAPI (https://rapidapi.com/rocketapi/api/rocketapi-for-instagram)
//

import { FetchingStrategy, IGQueryTypes, IG_QUERY_TYPES, InstagramHighlightProps, InstagramPostProps, InstagramProfileProps, InstagramQueryProps, InstagramStoryProps, InstagramUserQueryProps, RAPIDAPI_KEY, StrategyName } from "..";

export default class RocketAPI implements FetchingStrategy {
	public readonly strategyName: StrategyName = 'RocketAPI';

	async fetchProfile(username: string, includePosts: boolean): Promise<any> {
		if (includePosts) 
			// RocketAPI doesn't support fetching posts
			throw new Error("RocketAPI doesn't support fetching posts");
		const response = await fetch('https://rocketapi-for-instagram.p.rapidapi.com/instagram/user/get_info', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RapidAPI-Key': RAPIDAPI_KEY,
				'X-RapidAPI-Host': 'rocketapi-for-instagram.p.rapidapi.com'
			},
			body: JSON.stringify({ username: username })
		});
		const data = await response.json();
		// console.log(data);
		return this.processResponse("fetchProfile", data);
	}
	
	async fetchUsernameByInstagramID(instagramID: string): Promise<string | boolean> {
		const response = await fetch('https://rocketapi-for-instagram.p.rapidapi.com/instagram/user/get_info_by_id', {
		    method: 'POST',
		    headers: {
		        'content-type': 'application/json',
		        'X-RapidAPI-Key': RAPIDAPI_KEY,
		        'X-RapidAPI-Host': 'rocketapi-for-instagram.p.rapidapi.com'
		    },
		    body: JSON.stringify({id: instagramID})
		});
		const data = await response.json();
		if (data.status === "done" && data.response.status_code === 200 && data.response.body.user.username) {
			return data.response.body.user.username;
		} else {
		    return false;
		}
	}

	async fetchInstagramQuery(query: string, queryType: string, limit: number = 15, attemptNumber: number = 0, backup: boolean = false): Promise<boolean | InstagramProfileProps | [InstagramQueryProps] | { error: string }> {
		const response = await fetch('https://rocketapi-for-instagram.p.rapidapi.com/instagram/search', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RapidAPI-Key': RAPIDAPI_KEY,
				'X-RapidAPI-Host': 'rocketapi-for-instagram.p.rapidapi.com'
			},
			body: JSON.stringify({ query: query })
		});
		const data = await response.json();
		console.log(data);
		return this.processResponse("fetchInstagramQuery", data, queryType as IGQueryTypes);
	}

	async fetchSimilarInstagramAccounts(instagramId: string, limit: number = 15): Promise<boolean | InstagramProfileProps | [InstagramQueryProps] | { error: string }> {
		const response = await fetch('https://rocketapi-for-instagram.p.rapidapi.com/instagram/user/get_similar_accounts', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RapidAPI-Key': RAPIDAPI_KEY,
				'X-RapidAPI-Host': 'rocketapi-for-instagram.p.rapidapi.com'
			},
			body: JSON.stringify({ id: instagramId })
		});
		const data = await response.json();
		console.log(`fetchSimilarInstagramAccounts(${instagramId}, ${limit}):`)
		console.log(data);
		return this.processResponse("fetchSimilarInstagramAccounts", data, IG_QUERY_TYPES.similarUsers);
	}

	async fetchUserFollowers(instagramId: string, limit: number = 15): Promise<boolean | InstagramProfileProps | [InstagramQueryProps] | { error: string }> {
		const response = await fetch('https://rocketapi-for-instagram.p.rapidapi.com/instagram/user/get_followers', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RapidAPI-Key': RAPIDAPI_KEY,
				'X-RapidAPI-Host': 'rocketapi-for-instagram.p.rapidapi.com'
			},
			body: JSON.stringify({ 
				id: instagramId,
				count: limit,
				max_id: null
			})
		});
		const data = await response.json();
		// console.log(data);
		return this.processResponse("fetchUserFollowers", data, IG_QUERY_TYPES.similarUsers);
	}

	async fetchStoriesByInstagramID?(instagramId: string): Promise<{ profile: InstagramProfileProps, stories: InstagramStoryProps[] }> {
		const response = await fetch('https://rocketapi-for-instagram.p.rapidapi.com/instagram/user/get_stories', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RapidAPI-Key': RAPIDAPI_KEY,
				'X-RapidAPI-Host': 'rocketapi-for-instagram.p.rapidapi.com'
			},
			body: JSON.stringify({ ids: [parseInt(instagramId)] })
		});
		const data = await response.json();
		console.log(data);

		if (data.status !== "done" || data.response.status_code !== 200) {
			throw new Error(`Failed to fetch stories from RocketAPI: ${data.response.body.status}`);
		}

		const reelData = data.response.body.reels[instagramId];

		if (!reelData) {
			throw new Error(`No stories found for Instagram ID: ${instagramId}`);
		}

		const profile: InstagramProfileProps = {
			graphql: {
				user: {
					id: reelData.user.pk,
					username: reelData.user.username,
					full_name: reelData.user.full_name,
					profile_pic_url: reelData.user.profile_pic_url,
					is_private: reelData.user.is_private,
					is_verified: reelData.user.is_verified
				}
			}
		};

		const stories: InstagramStoryProps[] = reelData.items.map(item => ({
			id: item.id,
			taken_at: item.taken_at,
			media_type: item.media_type,
			shortcode: item.code,
			image_url: item.image_versions2.candidates[0].url,
			video_url: item.video_versions ? item.video_versions[0].url : null,
			video_view_count: item.view_count || 0,
			accessibility_caption: item.accessibility_caption || null
		}));

		return { profile, stories };
	}

	async fetchHighlightsByInstagramID(instagramId: string): Promise<{ profile: InstagramProfileProps, highlights: InstagramHighlightProps[] }> {
		const response = await fetch('https://rocketapi-for-instagram.p.rapidapi.com/instagram/user/get_highlights', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RapidAPI-Key': RAPIDAPI_KEY,
				'X-RapidAPI-Host': 'rocketapi-for-instagram.p.rapidapi.com'
			},
			body: JSON.stringify({ id: parseInt(instagramId) })
		});
		const data = await response.json();

		if (data.status !== "done" || data.response.status_code !== 200) {
			throw new Error(`Failed to fetch highlights from RocketAPI: ${data.response.body.status}`);
		}

		const highlightsData = data.response.body.data.user.edge_highlight_reels.edges;

		if (!highlightsData || highlightsData.length === 0) {
			throw new Error(`No highlights found for Instagram ID: ${instagramId}`);
		}

		const profile: InstagramProfileProps = {
			graphql: {
				user: {
					id: instagramId,
					username: highlightsData[0].node.owner.username,
					profile_pic_url: highlightsData[0].node.owner.profile_pic_url
				}
			}
		};

		const highlights: InstagramHighlightProps[] = highlightsData.map(edge => ({
			id: edge.node.id,
			title: edge.node.title,
			cover_media_url: edge.node.cover_media.thumbnail_src,
			items: [] // Note: We don't have access to individual story items in this API response
		}));

		return { profile, highlights };
	}

	async fetchPostsByInstagramID(instagramId: string): Promise<{ profile: InstagramProfileProps, posts: InstagramPostProps[] }> {
		const response = await fetch('https://rocketapi-for-instagram.p.rapidapi.com/instagram/user/get_media', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RapidAPI-Key': RAPIDAPI_KEY,
				'X-RapidAPI-Host': 'rocketapi-for-instagram.p.rapidapi.com'
			},
			body: JSON.stringify({ id: parseInt(instagramId), count: 12, max_id: null })
		});
		const data = await response.json();

		if (data.status !== "done" || data.response.status_code !== 200) {
			throw new Error(`Failed to fetch posts from RocketAPI: ${data.response.body.status}`);
		}

		const postsData = data.response.body.items;

		if (!postsData || postsData.length === 0) {
			throw new Error(`No posts found for Instagram ID: ${instagramId}`);
		}

		const profile: InstagramProfileProps = {
			graphql: {
				user: {
					id: instagramId,
					username: data.response.body.user.username,
					full_name: data.response.body.user.full_name,
					profile_pic_url: data.response.body.user.profile_pic_url,
					is_private: data.response.body.user.is_private,
					is_verified: data.response.body.user.is_verified
				}
			}
		};

		console.log(`Fetched profile for ${instagramId}`);
		console.log(profile);

		const posts: InstagramPostProps[] = postsData.map(post => ({
			id: post.code,
			caption: post.caption?.text || null,
			media_type: post.media_type,
			image_url: post.image_versions2?.candidates[0]?.url || null,
			video_url: post.video_versions ? post.video_versions[0].url : null,
			like_count: post.like_count,
			comment_count: post.comment_count,
			taken_at: post.taken_at,
			is_video: post.media_type === 2,
			accessibility_caption: post.accessibility_caption || null,
			is_album: post.media_type === 8,
			album_children: post.carousel_media ? post.carousel_media.map(child => ({
				id: child.id,
				image_url: child.image_versions2?.candidates[0]?.url || null,
				is_video: child.media_type === 2,
				video_url: child.video_versions ? child.video_versions[0].url : null,
				accessibility_caption: child.accessibility_caption || null
			})) : null
		}));

		return { profile, posts };
	}


	async processResponse(caller: string, response: any, queryType?: IGQueryTypes): Promise<[InstagramQueryProps] | InstagramProfileProps | { error: string } | boolean> {
		let results: InstagramProfileProps;

		if (response.status === "done" && response.response.status_code === 404)
			return false; // Username not found (404)

		if (response.status !== "done" || response.response.status_code !== 200)
			throw new Error(`Failed to fetch data from RocketAPI: ${response.response.status_message}`);

		if (caller == "fetchProfile") {
			if (!response.response.body || !response.response.body.data || !response.response.body.data.user || !response.response.body.data.user.id) {
				// Received null user object, so the username most likely doesn't exist
				console.log(`Username doesn't exist.`);
				return false;
			}

			results = {
				graphql: {
					user: {
						id: response.response.body.data.user.id,
						username: response.response.body.data.user.username,
						full_name: response.response.body.data.user.full_name,
						biography: response.response.body.data.user.biography,
						category: response.response.body.data.user.category || response.response.body.data.user.category_name,
						external_url: response.response.body.data.user.external_url,
						external_url_title: response.response.body.data.user.bio_links && response.response.body.data.user.bio_links.length && response.response.body.data.user.bio_links[0].title ? response.response.body.data.user.bio_links[0].title : null,
						profile_pic_url: response.response.body.data.user.profile_pic_url,
						profile_pic_url_hd: response.response.body.data.user.profile_pic_url_hd,
						follower_count: response.response.body.data.user.edge_followed_by.count,
						following_count: response.response.body.data.user.edge_follow.count,
						media_count: response.response.body.data.user.edge_owner_to_timeline_media.count,
						is_private: response.response.body.data.user.is_private,
						is_verified: response.response.body.data.user.is_verified,
						is_business: response.response.body.data.user.is_business || response.response.body.data.user.is_business_account,
						is_professional: response.response.body.data.user.is_professional || response.response.body.data.user.is_professional_account,
						edge_followed_by: response.response.body.data.user.edge_followed_by,
						edge_follow: response.response.body.data.user.edge_follow,
						edge_owner_to_timeline_media: response.response.body.data.user.edge_owner_to_timeline_media.edges.map(edge => ({
							id: edge.node.shortcode,
							caption: edge.node.edge_media_to_caption.edges[0]?.node.text,
							location: edge.node.location && edge.node.location.name ? edge.node.location.name : null,
							image_url: edge.node.display_url,
							hd_image_url: edge.node.thumbnail_resources[edge.node.thumbnail_resources.length - 1]?.src,
							like_count: edge.node.edge_liked_by.count,
							comment_count: edge.node.edge_media_to_comment.count,
							taken_at: edge.node.taken_at_timestamp,
							accessibility_caption: edge.node.accessibility_caption ? edge.node.accessibility_caption : null,
							is_video: edge.node.is_video,
							video_view_count: edge.node.video_view_count ? edge.node.video_view_count : null,
							is_album: edge.node.edge_sidecar_to_children ? true : false,
							album_children: edge.node.edge_sidecar_to_children && edge.node.edge_sidecar_to_children.edges && edge.node.edge_sidecar_to_children.edges.length ? edge.node.edge_sidecar_to_children.edges.map((child: any) => ({
								id: child.node.shortcode,
								image_url: child.node.display_url,
								is_video: child.node.is_video,
								video_url: child.node.video_url ? child.node.video_url : null,
								video_view_count: child.node.video_view_count ? child.node.video_view_count : null,
								accessibility_caption: child.node.accessibility_caption ? child.node.accessibility_caption : null,
							})) : null,
						}))
					}
				}
			};
			// delete results.graphql.user.edge_felix_video_timeline;
			// delete results.graphql.user.edge_owner_to_timeline_media.edges;
			// delete results.graphql.user.edge_saved_media;
			// delete results.graphql.user.edge_media_collections;
		
		} else if (caller == "fetchInstagramQuery") {
			switch (queryType) {
				case IG_QUERY_TYPES.users:
					if (response.response.body.users) {
						results = response.response.body.users.map((user: any) => ({
							full_name: user.user.full_name,
							avatar_url: user.user.profile_pic_url,
							profile_pic_url: user.user.profile_pic_url,
							username: user.user.username,
							is_verified: user.user.is_verified,
							is_private: user.user.is_private,
							link: `instagram/${user.user.username}/`,
							type: "ig",
							user_id: user.user.pk,
							follower_count: 0,
						}));
					}
					break;
				case IG_QUERY_TYPES.hashtags:
					if (response.response.body.hashtags) {
						results = response.response.body.hashtags.map((hashtag: any) => ({
							name: hashtag.hashtag.name,
							profile_pic_url: hashtag.hashtag.profile_pic_url,
							target_id: hashtag.hashtag.id,
							mediaCount: hashtag.hashtag.media_count,
							search_result_subtitle: hashtag.hashtag.search_result_subtitle,
						}));
					}
					break;
				case IG_QUERY_TYPES.places:
					if (response.response.body.places) {
						results = response.response.body.places.map((place: any) => ({
							target: place.place.title,
							target_id: place.place.location.pk,
							address: place.place.subtitle ? place.place.subtitle : "–",
						}));
					}
					break;
			}
		} else if (caller == "fetchSimilarInstagramAccounts") {
			if (response.status === "done" && response.response.status_code === 200) {
				results = response.response.body.data.user.edge_chaining.edges.map((edge: any) => ({
					id: edge.node.id,
					full_name: edge.node.full_name,
					is_verified: edge.node.is_verified,
					profile_pic_url: edge.node.profile_pic_url,
					username: edge.node.username,
					is_private: edge.node.is_private,
				}));
			}
		} else if (caller == "fetchUserFollowers") {
			if (response.status === "done" && response.response.status_code === 200) {
				results = response.response.body.users.map((user: any) => ({
					full_name: user.full_name,
					avatar_url: user.profile_pic_url,
					profile_pic_url: user.profile_pic_url,
					username: user.username,
					is_verified: user.is_verified,
					is_private: user.is_private,
					user_id: user.pk,
				}));
			}
		}
		return results;
	}
}