//
// InstagramBulkProfileScrapper (https://rapidapi.com/thekirtan/api/instagram-bulk-profile-scrapper)
//

import { FetchingStrategy, InstagramAlbumChildPostProps, InstagramPostProps, InstagramProfileProps, InstagramQueryProps, RAPIDAPI_KEY, StrategyName } from "..";

export default class InstagramBulkProfileScrapper implements FetchingStrategy {
	public readonly strategyName: StrategyName = 'InstagramBulkProfileScrapper';

	async fetchProfile(username: string, includePosts: boolean): Promise<any> {
		const endpoint = `https://instagram-bulk-profile-scrapper.p.rapidapi.com/clients/api/ig/ig_profile?response_type=${includePosts ? "full" : "short"}&corsEnabled=false&ig=${username}`;
		const response = await fetch(endpoint, {
			headers: {
				"X-RapidAPI-Key": RAPIDAPI_KEY,
				"X-RapidAPI-Host": "instagram-bulk-profile-scrapper.p.rapidapi.com",
			},
		});
		const data = await response.json();
		console.log("fetchProfile:");
		console.log(data);
		return this.processResponse("fetchProfile", data, "users", includePosts);
	}

	async fetchUsernameByInstagramID(instagramID: string): Promise<string | boolean> {
		const endpoint = `https://instagram-bulk-profile-scrapper.p.rapidapi.com/clients/api/ig/pk_to_username?pk=${instagramID}`;
		const response = await fetch(endpoint, {
			headers: {
				"X-RapidAPI-Key": RAPIDAPI_KEY,
				"X-RapidAPI-Host": "instagram-bulk-profile-scrapper.p.rapidapi.com",
			},
		});
		const data = await response.json();
		console.log("fetchUsernameByInstagramID:");
		console.log(data);
		if (data && data.data && data.data.username) {
			return data.data.username;
		} else {
			return false;
		}
	}

	async fetchInstagramQuery(query: string, queryType: string, limit: number = 15, attemptNumber: number = 0, backup: boolean = false): Promise<any> {
		let endpoint: string;
		switch(queryType) {
			case "users":
				endpoint = `https://instagram-bulk-profile-scrapper.p.rapidapi.com/clients/api/ig/ig_profile?response_type=search&ig=${query}`;
				break;
			case "hashtags":
				endpoint = `https://instagram-bulk-profile-scrapper.p.rapidapi.com/clients/api/ig/search_hashtags?name=${query}`;
				break;
			case "places":
				endpoint = `https://instagram-bulk-profile-scrapper.p.rapidapi.com/clients/api/ig/search_places?name=${query}`;
				break;
			default:
				throw new Error(`Invalid query type: ${queryType}`);
		}
		const response = await fetch(endpoint, {
			headers: {
				"X-RapidAPI-Key": RAPIDAPI_KEY,
				"X-RapidAPI-Host": "instagram-bulk-profile-scrapper.p.rapidapi.com",
			},
		});
		const data = await response.json();
		console.log(data);
		return this.processResponse("fetchInstagramQuery", data);
	}

	async fetchSimilarInstagramAccounts(instagramId: string, limit: number = 15): Promise<any> {
		return false;
	}

	async fetchUserFollowers(instagramId: string, limit: number = 15): Promise<boolean | InstagramProfileProps | [InstagramQueryProps] | { error: string }> {
		return false;
	}

	processResponse(caller: string, response: any, queryType?: string, includePosts?: boolean): InstagramProfileProps | any {
		let results: any;

		if (caller == "fetchProfile") {
			// Fetching IG profile
			if (response && Array.isArray(response) && response.length && response[0].username) {

				if (Object.prototype.hasOwnProperty.call(response[0], "follower_count")) {
					response[0].id = response[0].pk;
					response[0].edge_followed_by = { count: response[0].follower_count };
					response[0].edge_follow = { count: response[0].following_count };
					console.log("Response before copyProfile:");
					console.log(response);
					if (!includePosts)
						response[0].edge_owner_to_timeline_media = { count: response[0].media_count };
					else if (response[0].feed && response[0].feed.data && response[0].feed.data.length)
						response[0].edge_owner_to_timeline_media = response[0].feed.data.map((post: any) => {
							let instagramPostProps: InstagramPostProps = {
								id: post.code,
								caption: post.caption.text,
								location: post.location && post.location.name ? post.location.name : null,
								image_url: post.image_versions2.candidates[0].url,
								hd_image_url: post.image_versions2.candidates[0].url,
								like_count: post.like_count,
								comment_count: post.comment_count,
								taken_at: post.taken_at,
								video_view_count: post.view_count ? post.view_count : null,
								is_video: post.media_type === 2 ? true : false,
								video_url: post.media_type === 2 && post.video_versions && post.video_versions.length ? post.video_versions[0].url : null,
								is_album: post.media_type === 8 ? true : false,
								album_children: post.media_type === 8 && post.carousel_media && post.carousel_media.length ? post.carousel_media.map((child: any) => {
									let instagramAlbumChildPostProps: InstagramAlbumChildPostProps = {
										id: child.shortcode,
										image_url: child.image_versions2.candidates[0].url,
										is_video: child.media_type === 2 ? true : false,
										// This is all the data returned by InstagramBulkProfileScrapper API
									}
									return instagramAlbumChildPostProps;
								}) : null,
							}
							return instagramPostProps;
						});
						if (response[0].feed && response[0].feed.data) {
							response[0].edge_owner_to_timeline_media.count = response[0].feed.data.length;
						} else {
							response[0].edge_owner_to_timeline_media.count = 0;
						}
				}
				results = { graphql: { user: response[0] } };
				// console.log("Response after copyProfile:");
				// console.log(response);
			}

		} else if (caller == "fetchInstagramQuery") {
			// Fetching IG query
			response = response.data;
			if (response && response.length) {
				if (response[0].users && response[0].users.length) {
					results = response[0].users.map((user: any) => ({
						full_name: user.full_name,
						avatar_url: user.profile_pic_url,
						profile_pic_url: user.profile_pic_url,
						username: user.username,
						is_verified: user.is_verified,
						is_private: user.is_private,
						link: `instagram/${user.username}/`,
						type: "ig",
						user_id: user.pk,
						follower_count: 0,
					}));
				} else if (Array.isArray(response)) {
					// Is hashtag
					results = response.map((data) => ({
						name: data.name,
						profile_pic_url: data.profile_pic_url,
						target_id: data.id,
						mediaCount: data.media_count,
						search_result_subtitle: data.search_result_subtitle,
					}));
				} else if (response[0].places && response[0].places.length) {
					results = response[0].places.map((place) => ({
						target: place.location.name,
						target_id: place.location.pk,
						address: place.location.address ? place.location.address : "–",
					}));
				}
			}
		}

		// console.log("Results in processResponse:");
		// console.log(results);
		return results;
	}
}