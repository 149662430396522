import urlJoin from 'url-join';
import { redisClient } from '../redis';
import RocketAPI from './providers/RocketAPI';
import UpleapAPI from './providers/UpleapAPI';
import InstagramBulkProfileScrapper from './providers/InstagramBulkProfileScrapper';
import InstagramScraper2022 from './providers/InstagramScraper2022';

export const RAPIDAPI_KEY = "d4ee58a8c6mshf0a1a179a3ad022p107414jsn0c4b4e768319"
export const IG_QUERY_TYPES = {
	profile: 'profile',
	users: 'users',
	hashtags: 'hashtags',
	places: 'places',
	similarUsers: 'similarUsers',
	userFollowers: 'userFollowers',
} as const;
export type IGQueryTypes = typeof IG_QUERY_TYPES[keyof typeof IG_QUERY_TYPES];

export type InstagramProfileProps = {
	graphql: {
		user: {
			id: string,
			username: string,
			full_name?: string,
			biography?: string,
			category?: string,
			external_url?: string,
			external_url_title?: string,
			profile_pic_url?: string,
			profile_pic_url_hd?: string,
			follower_count?: number,
			following_count?: number,
			media_count?: number,
			is_private?: boolean,
			is_verified?: boolean,
			is_business?: boolean,
			is_professional?: boolean,
			edge_followed_by?: any,
			edge_follow?: any,
			edge_owner_to_timeline_media?: Array<InstagramPostProps>
		} 
	}
} | false;

export type InstagramStoryProps = {
	id: string,
	taken_at: number,
	media_type: number,
	shortcode: string,
	image_url: string,
	video_url: string,
	video_view_count: number,
	accessibility_caption?: string
	// is_paid_partnership?: boolean
}

export type InstagramHighlightProps = {
	id: string,
	title: string,
	cover_media_url: string,
	items: Array<any>
}

export type InstagramHashtagQueryProps = {
	name: string,
	target_id: string,
	mediaCount?: number,
	search_result_subtitle?: string
};

export type InstagramPlaceQueryProps = {
	target_id: string,
	address: string,
};

export type InstagramUserQueryProps = {
	full_name: string,
	avatar_url: string,
	profile_pic_url: string,
	username: string,
	is_verified: boolean,
	is_private: boolean,
	link: string,
	type: string,
	user_id: string,
	follower_count?: number
}

export type InstagramQueryProps = InstagramHashtagQueryProps | InstagramPlaceQueryProps | InstagramUserQueryProps;

export type InstagramPostProps = {
	id: string,
	caption: string,
	location?: string,
	image_url: string,
	hd_image_url: string,
	like_count: number,
	comment_count: number,
	video_view_count: number,
	taken_at: number,
	accessibility_caption?: string
	is_video?: boolean
	video_url?: boolean
	is_album?: boolean
	album_children?: Array<InstagramAlbumChildPostProps>
};

export type InstagramAlbumChildPostProps = {
	id: string,
	image_url: string,
	is_video?: boolean
	video_url?: boolean
	video_view_count?: number
	accessibility_caption?: string
};

export type InstagramAnalyticsProps = {
	engagement_rate: number,
	follower_to_following_ratio: number,
	avg_likes_per_post: number,
	avg_comments_per_post: number,
	like_to_followers_ratio: number,
	like_to_comment_ratio: number,
	comment_to_followers_ratio: number,
	best_time_to_post: string,
	worst_time_to_post: string,
	most_successful_post: string,
	least_successful_post: string,
	top_5_interests: {
		[key: string]: number
	},
	top_5_countries: {
		[key: string]: number
	},
	top_5_traffic_sources: {
		[key: string]: number
	},
	age_groups: {
		[key: string]: number
	},
	categories: string[]
};

export interface FetchingStrategy {
	strategyName: StrategyName;
	fetchProfile(username: string, includePosts: boolean): Promise<any>;
	fetchUsernameByInstagramID(instagramId: string): Promise<string | boolean>;
	fetchInstagramQuery(query: string, queryType: IGQueryTypes, limit?: number, attemptNumber?: number, backup?: boolean): Promise<any>;
	fetchSimilarInstagramAccounts(instagramId: string, limit?: number, attemptNumber?: number, backup?: boolean): Promise<any>;
	fetchUserFollowers(userInstagramId: string, limit?: number): Promise<boolean | InstagramProfileProps | [InstagramQueryProps] | { error: string }>;
	fetchPostsByInstagramID?(instagramId: string): Promise<{ profile: InstagramProfileProps, posts: InstagramPostProps[] }>;
	fetchStoriesByInstagramID?(instagramId: string): Promise<{ profile: InstagramProfileProps, stories: InstagramStoryProps[] }>;
	fetchHighlightsByInstagramID?(instagramId: string): Promise<{ profile: InstagramProfileProps, highlights: InstagramHighlightProps[] }>;
	processResponse(caller: string, response: any, queryType?: string, includePosts?: boolean): Promise<any>;
}

export type StrategyName = 'RocketAPI' | 'InstagramBulkProfileScrapper' | 'InstagramScraper2022' | 'UpleapAPI';
const strategies = [
	new RocketAPI(), 
	new UpleapAPI(),
	new InstagramScraper2022(),
	new InstagramBulkProfileScrapper(), 
];

async function cacheOrFetch(redisKey: string, queryType: IGQueryTypes, ignoreCache = false, fetchFunction: () => Promise<any>): Promise<any> {
    let data: any;
    if (!ignoreCache) {
        const cachedData = await redisClient.get(redisKey);
        if (cachedData)
            return cachedData;
    }
    data = await fetchFunction();
    if (typeof data === "boolean")
        return data; // If data is of type boolean, it means that the query returned no results
    if (!data)
        throw new Error(`No data fetched for query: ${redisKey}`);
    // Cache for 6 hours for users, 30 days for hashtags and places, and forever for profiles
    const expiration = queryType === IG_QUERY_TYPES.users ? 6 * 60 * 60 : queryType === IG_QUERY_TYPES.profile ? -1 : 30 * 24 * 60 * 60;
    try {
        if (expiration === -1) {
            await redisClient.set(redisKey, JSON.stringify(data));
        } else {
            await redisClient.setex(redisKey, expiration, JSON.stringify(data));
        }
    } catch (error) {
        console.error("Error saving data in Redis: ", error);
    }
    return data;
}

function getNextStrategy(strategyIndex: number): { strategy: FetchingStrategy; nextIndex: number } {
	const nextIndex = (strategyIndex + 1) % strategies.length;
	return { strategy: strategies[nextIndex], nextIndex };
}

async function fetchData(config: { redisKey?: string; maxRetries: number; fetchFunction: (strategy: FetchingStrategy) => Promise<any>; preferredStrategy?: FetchingStrategy, forcePreferredStrategy?: boolean }): Promise<any> {
	let attemptNumber = 0;
	let strategyIndex = -1;
	const errors: any[] = [];
	while (attemptNumber < config.maxRetries) {
		let strategy;
		if (attemptNumber === 0 && config.preferredStrategy) {
			strategy = config.preferredStrategy;
		} else if (!config.forcePreferredStrategy) {
			const { strategy: nextStrategy, nextIndex } = getNextStrategy(strategyIndex);
			strategy = nextStrategy;
			strategyIndex = nextIndex;
		}
		try {
			return await config.fetchFunction(strategy);
		} catch (error) {
			if (!error.message.includes("Skipping"))
				console.log(error); // Silent skipping straregy errors
			errors.push(`Strategy ${strategy.strategyName}: ${error.message}`);
			if (strategyIndex === 0 || config.preferredStrategy || config.forcePreferredStrategy) 
				attemptNumber += 1;
		}
	}
	throw new Error(`Failed to fetch data after ${config.maxRetries} retries with all strategies. Errors: ${errors.join("; ")}`);
}

export class Instagram {
	public static async fetchInstagramProfile(
		username: string, 
		includePosts = false, 
		ignoreCache = false, 
		preferredStrategy: FetchingStrategy = null, 
		maxRetries = 3, 
		forcePreferredStrategy = false
	): Promise<InstagramProfileProps | any> {
		// if inlcudePosts is true, then the profile will be fetched using the InstagramBulkProfileScrapper strategy
		const redisKey = `ig_profile_${includePosts ? "full_" : ""}${username.replaceAll(" ", "")}`;
		console.log(`[fetchInstagramProfile] redisKey: ${redisKey} includePosts: ${includePosts} ignoreCache: ${ignoreCache} preferredStrategy: ${preferredStrategy} forcePreferredStrategy: ${forcePreferredStrategy}`);
		return await cacheOrFetch(redisKey, IG_QUERY_TYPES.profile, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries,
				preferredStrategy,
				forcePreferredStrategy,
				fetchFunction: async (strategy: FetchingStrategy) => {
					console.log(`🔎 Fetching IG profile '${username}' on attempt #${strategies.indexOf(strategy)+1} (${strategy.strategyName})...`);
					console.log(`Include posts: ${includePosts}`)
					console.log(`Force preferred strategy: ${forcePreferredStrategy}, strategy: ${preferredStrategy ? preferredStrategy.strategyName : "null"}`)
					return await strategy.fetchProfile(username, includePosts);
				},
			});
		});
	}

	// fetchUsernameByInstagramID is only supported by InstagramBulkProfileScrapper and RocketAPI
	public static async fetchUsernameByInstagramID(
		instagramID: string
	): Promise<string | boolean> {
		return await fetchData({
			maxRetries: 2,
			fetchFunction: async (strategy: FetchingStrategy) => {
				if (strategy.strategyName === 'InstagramBulkProfileScrapper') {
					console.log(`🔎 Fetching IG username for ID '${instagramID}' using InstagramBulkProfileScrapper...`);
					return await strategy.fetchUsernameByInstagramID(instagramID);
				} else if (strategy.strategyName === 'RocketAPI') {
					console.log(`🔎 InstagramBulkProfileScrapper failed. Trying with RocketAPI for ID '${instagramID}'...`);
					return await strategy.fetchUsernameByInstagramID(instagramID);
				}
				throw new Error(`🔵 Skipping ${strategy.strategyName} for fetchUsernameByInstagramID as it is not supported.`);
			},
		});
	}

	public static async fetchInstagramQuery(
		query: string,
		queryType: IGQueryTypes,
		limit = 15,
		preferredStrategy: FetchingStrategy = null,
		ignoreCache = false,
		maxRetries = 3,
		backup = false,
		forcePreferredStrategy = false
	): Promise<[InstagramQueryProps] | { error: string }> {
		if (!IG_QUERY_TYPES[queryType])
			return { error: `Invalid query type: ${queryType}` };
		
		const redisKey = `ig_query_${queryType}_${query}`;
		return await cacheOrFetch(redisKey, queryType, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries,
				preferredStrategy,
				forcePreferredStrategy,
				fetchFunction: async (strategy: FetchingStrategy) => {
					// Skip InstagramScraper2022 for fetchInstagramQuery as it's not supported
					if (strategy.strategyName === 'InstagramScraper2022') {
						console.log('Skipping InstagramScraper2022 for fetchInstagramQuery as it is not supported.');
						throw new Error('Skipping InstagramScraper2022');
					}
					// Skip RocketAPI for hashtags and places queries
					if ((queryType === 'hashtags' || queryType === 'places') && strategy.strategyName === 'RocketAPI') {
						console.log('Skipping RocketAPI for hashtags and places queries as it is not supported.');
						throw new Error('Skipping RocketAPI for hashtags and places');
					}
					console.log(`🔎 Fetching ${queryType ? `${queryType}` : ""} IG query '${query}' on attempt #${strategies.indexOf(strategy)+1} (${strategy.strategyName})...`);
					return await strategy.fetchInstagramQuery(query, queryType, limit, maxRetries, backup);
				},
			});
		});
	}

	public static async fetchSimilarInstagramAccounts(
		instagramId: string,
		limit = 15,
		preferredStrategy: FetchingStrategy = null,
		ignoreCache = false,
		maxRetries = 3,
		backup = false,
		forcePreferredStrategy = false
	): Promise<[InstagramUserQueryProps] | { error: string }> {
		const redisKey = `ig_similar_for_${instagramId}`;
		return await cacheOrFetch(redisKey, IG_QUERY_TYPES.users, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries,
				preferredStrategy,
				forcePreferredStrategy,
				fetchFunction: async (strategy: FetchingStrategy) => {
					// Only RocketAPI and InstagramScraper2022 support fetchSimilarInstagramAccounts
					if (strategy.strategyName !== 'RocketAPI' && strategy.strategyName !== 'InstagramScraper2022') {
						console.log(`🔵 Skipping ${strategy.strategyName} for fetchSimilarInstagramAccounts as it is not supported.`);
						throw new Error(`🔵 Skipping ${strategy.strategyName} for fetchSimilarInstagramAccounts as it is not supported.`);
					}
					console.log(`🔎 Fetching similar IG accounts for '${instagramId}' on attempt #${strategies.indexOf(strategy)+1} (${strategy.strategyName})...`);
					return await strategy.fetchSimilarInstagramAccounts(instagramId, limit, maxRetries, backup);
				},
			});
		});
	}

	private static async fetchInstagramContent(
		fetchType: 'stories' | 'highlights' | 'posts',
		instagramId: string
	): Promise<{ profile: InstagramProfileProps, stories?: InstagramStoryProps[], highlights?: InstagramHighlightProps[], posts?: InstagramPostProps[] }> {
		const redisKey = `ig_${fetchType}_${instagramId}`;
		return await cacheOrFetch(redisKey, IG_QUERY_TYPES.profile, false, async () => {
			return await fetchData({
				redisKey,
				maxRetries: 3,
				preferredStrategy: strategies.find(s => s.strategyName === 'RocketAPI'),
				forcePreferredStrategy: true,
				fetchFunction: async (strategy: FetchingStrategy) => {
					if (strategy.strategyName !== 'RocketAPI') {
						console.log(`Skipping ${strategy.strategyName} for fetch${fetchType}ByInstagramID as it is only supported by RocketAPI.`);
						throw new Error(`fetch${fetchType}ByInstagramID is only supported by RocketAPI.`);
					}
					console.log(`🔎 Fetching IG ${fetchType} for '${instagramId}' using RocketAPI...`);
					switch (fetchType) {
						case 'stories':
							return await strategy.fetchStoriesByInstagramID(instagramId);
						case 'highlights':
							return await strategy.fetchHighlightsByInstagramID(instagramId);
						case 'posts':
							return await strategy.fetchPostsByInstagramID(instagramId);
						default:
							throw new Error(`Invalid fetchType: ${fetchType}`);
					}
				},
			});
		});
	}

	public static async fetchStoriesByInstagramID(instagramId: string): Promise<{ profile: InstagramProfileProps, stories: InstagramStoryProps[] }> {
		const result = await this.fetchInstagramContent('stories', instagramId);
		return { profile: result.profile, stories: result.stories as InstagramStoryProps[] };
	}

	public static async fetchHighlightsByInstagramID(instagramId: string): Promise<{ profile: InstagramProfileProps, highlights: InstagramHighlightProps[] }> {
		const result = await this.fetchInstagramContent('highlights', instagramId);
		return { profile: result.profile, highlights: result.highlights as InstagramHighlightProps[] };
	}

	public static async fetchPostsByInstagramID(instagramId: string): Promise<{ profile: InstagramProfileProps, posts: InstagramPostProps[] }> {
		const result = await this.fetchInstagramContent('posts', instagramId);
		return { profile: result.profile, posts: result.posts as InstagramPostProps[] };
	}

	public static async downloadInstagramAvatar(username: string, avatarUrl: string, supabaseClient: any, bucket: string='avatars', folder: string=''): Promise<string> {
		// console.log(`🔎 Downloading IG avatar for '${username}'...`);
		const response = await fetch(avatarUrl as string, { redirect: 'follow' });
		const fileData = await response.arrayBuffer();
		const imagePath = folder ? `${folder}/${username}.jpg` : `${username}.jpg`;
		const supabaseFileUrl = urlJoin(
		  process.env.NEXT_PUBLIC_SUPABASE_URL,
		  `/storage/v1/object/public/${bucket}`,
		  folder,
		  imagePath
		);
		const { data, error } = await supabaseClient.storage.from(bucket).upload(imagePath, fileData, { 
			upsert: true,
			contentType: 'image/jpeg',
		});
		if (error) {
			if (error.message.includes("The resource already exists"))
				return supabaseFileUrl;
			console.error(`Error uploading IG avatar for '${username}': ${error.message}`);
			throw new Error(error.message);
		}
		return supabaseFileUrl;
	}

	public static async fetchUserFollowers(userInstagramId: string, limit: number = 15,  ignoreCache = false,): Promise<any> {
		const redisKey = `ig_followers_for_${userInstagramId}`;
		return await cacheOrFetch(redisKey, IG_QUERY_TYPES.userFollowers, ignoreCache, async () => {
			return await fetchData({
				redisKey,
				maxRetries: 3,
				fetchFunction: async (strategy: FetchingStrategy) => {
					// Only RocketAPI and InstagramScraper2022 support fetchUserFollowers
					if (strategy.strategyName !== 'RocketAPI' && strategy.strategyName !== 'InstagramScraper2022') {
						// console.log(`🔵  Skipping ${strategy.strategyName} for fetchUserFollowers as it is not supported.`);
						throw new Error(`🔵 Skipping ${strategy.strategyName} for fetchUserFollowers as it is not supported.`);
					}
					console.log(`🔎 Fetching IG followers for '${userInstagramId}' on attempt #${strategies.indexOf(strategy)+1} (${strategy.strategyName})...`);
					return await strategy.fetchUserFollowers(userInstagramId, limit);
				},
			});
		});
	}
	
	public static async fetchAllCachedInstagramProfiles(): Promise<any> {
		// Returns the usernames of all the cached Instagram profiles
		let cursor = 0;
		let keys = [];
		do {
			const res = await redisClient.scan(cursor, { match: 'ig_profile_*', count: 1000 }); 
			cursor = parseInt(res[0], 10);
			keys.push(...res[1]);
		} while (cursor !== 0);
		if (!keys || keys.length === 0) return [];
		const usernames = keys.map((key) => key.replace("ig_profile_full_", "").replace("ig_profile_", ""));
		return [...new Set(usernames)]; // Remove duplicates
	}

	public static async isInstagramProfileCached(username: string): Promise<boolean> {
		let cursor = 0;
		do {
			const res = await redisClient.scan(cursor, { match: `ig_profile_*${username}`, count: 1000 });
			cursor = parseInt(res[0]);
			const keys = res[1];
			if (keys.length > 0) return true;
		} while (cursor !== 0);
		return false;
	}

	public static decodeInstagramMediaURL(url: string): string {
		if (url.includes(atob("LnRyYW5zbGF0ZS5nb29n"))) return url;
		const p = url.split("/"); let t = "";
		for (let i = 0; i < p.length; i++) 
			t += i == 2 ? p[i].replaceAll("-", "--").replaceAll(".", "-") + atob("LnRyYW5zbGF0ZS5nb29n") + "/" : i != p.length - 1 ? p[i] + "/" : p[i];
		return t;
	}

	public static graphqlIDToShortcode(graphqlID: string): string {
		const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
		let id = BigInt(graphqlID.split('_')[0]); // split by underscore and take the first part
		let shortcode = '';
		while (id > BigInt(0)) {
			let remainder = id % BigInt(64);
			id = id / BigInt(64);
			shortcode = alphabet.charAt(Number(remainder)) + shortcode;
		}
		return shortcode;
	}
}

