//
// Upleap API (https://api.upleap.com/api/v1/instagram/search?context=user&fast=true&query={username})
//

import { FetchingStrategy, IGQueryTypes, InstagramProfileProps, InstagramQueryProps, StrategyName } from "..";
import UpleapAPIKeyManager from "./UpleapAPIKeyManager";

// TODO: This key might get rate limited - maybe implement multiple keys?
// cURL command to get a new key:
// curl 'https://api.upleap.com/api/v1/oauth/login' \
//   -H 'authority: api.upleap.com' \
//   -H 'accept: application/json' \
//   -H 'accept-language: en-US,en;q=0.9' \
//   -H 'cache-control: no-cache' \
//   -H 'content-type: application/json' \
//   -H 'origin: https://app.upleap.com' \
//   -H 'pragma: no-cache' \
//   -H 'referer: https://app.upleap.com/' \
//   -H 'user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36' \
//   --data-raw '{"username":"dragos.balan.pr@gmail.com","password":"123123"}' \
//   --compressed

const UPLEAP_REQUEST_HEADERS = {
    'authority': 'api.upleap.com',
    'origin': 'https://upleap.com',
    'accept': 'application/json',
    'accept-language': 'en-US,en;q=0.9',
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    // Authorization: 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ4dHR1bzZtaGdwR1JmbjhSU2JuTXlJbkxBVUJYLTRUTGNaZVdqS0R0alRrIn0.eyJleHAiOjE3MjI2NTA2NTUsImlhdCI6MTcyMjA0NTg1NSwianRpIjoiZGM4OWI4NGQtZTQyYS00MGQwLThjNjMtYjBmZWNhNDBmOTRmIiwiaXNzIjoiaHR0cHM6Ly9pZC51cGxlYXAuY29tL3JlYWxtcy91cGxlYXAtcHJvZCIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiIwYTA5MDBkNy00NGNjLTQyZTEtODc2NC00ZTg4ZDRkNTQ4OTIiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJ1cGxlYXAiLCJzZXNzaW9uX3N0YXRlIjoiMTc3YTNjNzctNDgyMi00YzAwLTliNWUtMmYyZDY3Mjg2Yjk3IiwiYWNyIjoiMSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLXVwbGVhcC1wcm9kIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsInNpZCI6IjE3N2EzYzc3LTQ4MjItNGMwMC05YjVlLTJmMmQ2NzI4NmI5NyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6ImRiIGJiYXNiZmFzIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiZHJhZ29zLmJhbGFuLnByQGdtYWlsLmNvbSIsImdpdmVuX25hbWUiOiJkYiIsImZhbWlseV9uYW1lIjoiYmJhc2JmYXMiLCJlbWFpbCI6ImRyYWdvcy5iYWxhbi5wckBnbWFpbC5jb20ifQ.NORJChL5tN2gtX_MKp2nhrqxYDhMy3pqPBTuD-GDMl4cz2joxwGPQydv4QgeEiutJpor7b0nyAflpfoxh6sm5bMWPN5fqtlTQkXI8ZeR1dXeWuk4Px4cFnqDLNoTVtSUGYe72952A9idvoXSTae61-jhORXv0EkZGTyQ4p7evJMdt-i-kF416v6vHNS-SqmFOvvlUusfW02FUuta4rupJKyQHmeo_GIp9CJLo4hrOsdPzrI8uxHa0yjEfwWoYwf4T0esKrc0_MaFFiT6gRbs3jN-JU_qT9bte2ipCWQ5yC3zhcoXb5NC0glgo7okDuEMRP0WnKUn1Sg-RWseWwBSQA',
}

const HEADER_CACHE_EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour

export default class UpleapAPI implements FetchingStrategy {
    public readonly strategyName: StrategyName = 'UpleapAPI';
    private apiKeyManager: UpleapAPIKeyManager;
    private static cachedHeaders: Record<string, string> | null = null;
    private static headerExpirationTime: number = 0;

    constructor() {
        this.apiKeyManager = UpleapAPIKeyManager.getInstance();
    }

    private async getHeaders() {
        const currentTime = Date.now();
        if (UpleapAPI.cachedHeaders && currentTime < UpleapAPI.headerExpirationTime) {
            return UpleapAPI.cachedHeaders;
        }

        const token = await this.apiKeyManager.getToken();
        UpleapAPI.cachedHeaders = {
            ...UPLEAP_REQUEST_HEADERS,
            'Authorization': `Bearer ${token}`,
        };
        UpleapAPI.headerExpirationTime = currentTime + HEADER_CACHE_EXPIRATION_TIME;
        return UpleapAPI.cachedHeaders;
    }

    // async getNewAPIKey(): Promise<string> {
    //     const endpoint = `https://api.upleap.com/api/v1/oauth/login`;
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: UPLEAP_REQUEST_HEADERS,
    //         body: JSON.stringify({
    //             username: "dragos.balan.pr@gmail.com",
    //             password: "123123"
    //         })
    //     };
    //     const response = await fetch(endpoint, requestOptions);
    //     const data = await response.json();
    //     if (data.status === "ok")
    //         return data.token;
    //     else
    //         throw new Error('Error fetching API key from Upleap');
    // }

	async fetchProfile(username: string, includePosts: boolean): Promise<any> {
		const endpoint = `https://api.upleap.com/api/v1/instagram/usernames/${username}`;
        const requestOptions = {
            method: 'GET',
            headers: await this.getHeaders()
        };
		const response = await fetch(endpoint, requestOptions);
		const data = await response.json();
        // console.log(data);
		return this.processResponse("fetchProfile", data, "users", includePosts);
	}

    async fetchUsernameByInstagramID(instagramID: string): Promise<string | boolean> {
		return false;
	}

	async fetchInstagramQuery(query: string, queryType: string, limit: number = 15, attemptNumber: number = 0, backup: boolean = false): Promise<any> {
		const upleapQueryTypeMap = {
			"users": "user",
			"hashtags": "hashtag",
			"places": "place"
		};
		const upleapQueryType = upleapQueryTypeMap[queryType];
		if (!upleapQueryType) {
			throw new Error(`Invalid query type: ${queryType}`);
		}
		const endpoint = `https://api.upleap.com/api/v1/instagram/search?context=${upleapQueryType}&fast=true&query=${query}`;
        const requestOptions = {
            method: 'GET',
            headers: await this.getHeaders()
        };

		const response = await fetch(endpoint, requestOptions);
        // console.log(response);
		const data = await response.json();
        console.log(data);
		return this.processResponse("fetchInstagramQuery", data, queryType as IGQueryTypes);
	}

    async fetchSimilarInstagramAccounts(instagramId: string, limit: number = 15): Promise<any> {
        return false;
    }

    async fetchUserFollowers(instagramId: string, limit: number = 15): Promise<boolean | InstagramProfileProps | [InstagramQueryProps] | { error: string }> {
		return false;
	}

	processResponse(caller: string, response: any, queryType?: IGQueryTypes, includePosts?: boolean): InstagramProfileProps | any  {
		let results: [InstagramQueryProps] | InstagramProfileProps;

		if (caller == "fetchProfile") {
			// Handling IG profile response
            if (response.status === "error" && response.error && response.error.message && response.error.message.includes("User not found"))
			    return false; // IG Username not found (404)

			if (response && response.status === "ok" && response.data) {
				const user = response.data;
				results = {
                    graphql: {
                        user: {
                            id: user.pk,
                            username: user.username,
                            full_name: user.full_name,
                            biography: user.biography,
                            profile_pic_url: user.profile_pic_url,
                            follower_count: user.follower_count,
                            following_count: user.following_count,
                            media_count: user.media_count,
                            edge_followed_by: {
                                count: user.follower_count
                            },
                            edge_follow: {
                                count: user.following_count
                            },
                            external_url: user.external_url,
                            category: user.category,
                            is_verified: user.is_verified,
                            is_private: user.is_private,
                            is_business: user.is_business,
                        }
                    }
				};
			}

		} else if (caller == "fetchInstagramQuery") {
			// Fetching IG query
            if (response && response.status === "ok") {
                switch (queryType) {
                    case "users":
                        if (response.users && response.users.length) {
                            results = response.users.map((user: any) => ({
                                full_name: user.user.full_name,
                                avatar_url: user.user.profile_pic_url,
                                profile_pic_url: user.user.profile_pic_url,
                                username: user.user.username,
                                is_verified: user.user.is_verified,
                                is_private: user.user.is_private,
                                link: `instagram/${user.user.username}/`,
                                type: "ig",
                                user_id: user.user.pk,
                                follower_count: 0,
                            }));
                        } else 
                            return false; // No results returned
                        break;

                    case "hashtags":
                        if (response.hashtags && response.hashtags.length) {
                            results = response.hashtags.map((hashtag: any) => ({
                                name: hashtag.hashtag.name,
                                target_id: hashtag.hashtag.id,
                                mediaCount: hashtag.hashtag.media_count,
                                search_result_subtitle: hashtag.hashtag.search_result_subtitle,
                            }));
                        } else if (response.see_more && response.see_more.list && response.see_more.list.length) {
                            results = response.see_more.list.map((item: any) => ({
                                name: item.keyword.name,
                                target_id: item.keyword.id,
                                mediaCount: 0, // This information is not provided in the response
                                search_result_subtitle: '', // This information is not provided in the response
                            }));
                        } else 
                            return false; // No results returned
                        break;

                    case "places":
                        if (response.places && response.places.length) {
                            results = response.places.map((place: any) => ({
                                target: place.place.location.title,
                                target_id: place.place.location && place.place.location.pk ? place.place.location.pk : null,
                                address: place.place.subtitle ? place.place.subtitle : "–",
                            }));
                        } else 
                            return false; // No results returned
                        break;

                    default:
                        throw new Error(`Invalid query type: ${queryType}`);
                        
                }

			} else
                throw new Error(`Invalid response from UpleapAPI: ${JSON.stringify(response)}`);
		}
		// console.log("Results in processResponse:");
		// console.log(results);
		return results;
	}
}
