import { get } from '@vercel/edge-config';

const UPLEAP_USERNAME = "dragos.balan.pr@gmail.com";
const UPLEAP_PASSWORD = "123123";

const VERCEL_API_TOKEN = "4naNDIaRRf5yyivNNhO5ftS5";
const EDGE_CONFIG_ID = "ecfg_7pgrd9agakmvpucbckrhuucp0mdk";
const TEAM_ID = "team_ECAFhc2nxu6muKFNY1VvPr4X";

class UpleapAPIKeyManager {
    private static instance: UpleapAPIKeyManager;
    private token: string | null = null;
    private expirationTime: number = 0;

    private constructor() { }

    public static getInstance(): UpleapAPIKeyManager {
        if (!UpleapAPIKeyManager.instance) {
            UpleapAPIKeyManager.instance = new UpleapAPIKeyManager();
        }
        return UpleapAPIKeyManager.instance;
    }

    public async getToken(): Promise<string> {
        if (!this.token || Date.now() > this.expirationTime) {
            await this.refreshToken();
        }
        return this.token!;
    }

    private async refreshToken(): Promise<void> {
        try {
            const storedToken = await get('upleapToken');
            if (storedToken) {
                this.token = storedToken as string;
                this.expirationTime = await get('upleapTokenExpiration') as number;

                if (Date.now() < this.expirationTime) {
                    return;
                }
            }

            const response = await fetch("https://api.upleap.com/api/v1/oauth/login", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    username: UPLEAP_USERNAME,
                    password: UPLEAP_PASSWORD
                })
            });

            const data = await response.json();

            if (data.status === "ok" && data.token) {
                this.token = data.token;
                this.expirationTime = Date.now() + 6 * 24 * 60 * 60 * 1000;

                await this.updateEdgeConfig('upleapToken', this.token);
                await this.updateEdgeConfig('upleapTokenExpiration', this.expirationTime);
            } else {
                throw new Error('Failed to refresh token');
            }
        } catch (error) {
            console.error('Error refreshing Upleap token:', error);
            throw error;
        }
    }

    private async updateEdgeConfig(key: string, value: string | number): Promise<void> {
        const url = `https://api.vercel.com/v1/edge-config/${EDGE_CONFIG_ID}/items?teamId=${TEAM_ID}`;
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${VERCEL_API_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                items: [
                    {
                        operation: 'upsert',
                        key,
                        value,
                    },
                ],
            }),
        });

        if (!response.ok) {
            throw new Error(`Failed to update Edge Config: ${response.statusText}`);
        }
    }
}

export default UpleapAPIKeyManager;